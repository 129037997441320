
import Cookies from "js-cookie";
import { fetchParameters, sendMail } from "@/assets/scripts/api";
import { state } from "@/assets/scripts/stores/store-form";

import { checkInput, INPUT_NOT_EMPTY, INPUT_VALID_MAIL, INPUT_VALID_PHONE } from "@/assets/scripts/formValidation";

const HONEYPOT_VALUE = 'Type here !'

export default {
  data() {
    this.TYPE_CLIENT = 'client'
    this.TYPE_FRANCHISE = 'franchise'

    return {
      status: 'ready',
      lastname: '',
      firstname: '',
      email: '',
      phone: '',
      message: '',
      optin: false,
      returnMessage: '',
      store: '',
      honeypot: HONEYPOT_VALUE,
      type: null,
      formData: {},
      zoneMessageHeight: '0'
    }
  },

  async fetch() {
    const apiData = await fetchParameters()
    this.formData = apiData.form
  },

  computed: {
    preselectedStoreId() { return state.storeId }
  },

  watch: {
    $route: {
      handler() {
        switch (this.$route.name) {
          case 'location-tireuse-biere':
            this.type = this.TYPE_CLIENT
            break
          case 'etablissements-detail':
            this.type = this.TYPE_CLIENT
            break
          case 'etablissements-popin':
            if (this.$route.params.popin) {
              this.type = this.TYPE_CLIENT
            } else {
              this.type = this.TYPE_FRANCHISE
            }
            break
          default:
            this.type = this.TYPE_FRANCHISE
        }
      },
      immediate: true, // watch on component mount
    },

    preselectedStoreId(value) { this.store = value },
  },

  mounted() {
    const mybeersCookie = Cookies.get('mybeers');
    if (mybeersCookie) {
      try {
        this.mybeersExtra = JSON.parse(mybeersCookie);
      } catch (e) {
        this.log("mybeers cookie parsing error:", e);
        this.mybeersExtra = {};
      }
    }
  },

  methods: {

    log(...args) {
      if (process.client) {
        console.log(...args);
      }
    },

    handleMessage(message,status = "ready") {
      this.status = status
      this.returnMessage = message
      this.zoneMessageHeight = '5rem';
    },

    handleSuccess() {
      this.handleMessage("Merci pour votre message, nous vous répondrons prochainement.","complete")

      // call Matomo tag Manager trigger named "mtm.FormSubmit"
      if (typeof window === 'object') {

        if (process.env.NODE_ENV === 'production') {

          // Matomo
          const _mtm = (window._mtm = window._mtm || []);
          if (_mtm && typeof _mtm.push === 'function') {
            _mtm.push({ event: 'mtm.FormSubmit' });
          }

          // GTM
          window.dataLayer = window.dataLayer || [];
          if (window.dataLayer && typeof window.dataLayer.push === 'function' && this.type === this.TYPE_FRANCHISE) {
            window.dataLayer.push({
              'event': 'gtm.FormSubmit'
            });
            this.log("GTM event sent");
          }

          // Facebook
          window.fbq = window.fbq || function() {};
          if (typeof window.fbq === 'function') {
            const mtmCampaign = this.mybeersExtra?.mtm_campaign;
            const leadParams = {
              formType: this.type === this.TYPE_FRANCHISE ? 'franchise' : 'client',
            };
            if (mtmCampaign) {
              leadParams.campaign = mtmCampaign;
            }
            window.fbq('track', 'Lead', leadParams);
            this.log("Facebook Pixel called");
          } else {
            this.log("Facebook Pixel not ready");
          }

          // LinkedIn
          window.lintrk = window.lintrk || function() {};
          if (typeof window.lintrk === 'function') {
            window.lintrk('track', 17951436);
            this.log("LinkedIn lead event tracked");
          } else {
            this.log("LinkedIn tracking not available.");
          }

        } else {
          this.log("Tracking disabled in development environment.");
        }
      }

      setTimeout(() => {
        this.status = 'ready'
        this.lastname = ''
        this.firstname = ''
        this.email = ''
        this.phone = ''
        this.message = ''
        this.store = ''
        this.optin = false
        this.zoneMessageHeight = '0';
      }, 5000)
    },

    validateForm() {
      if (this.honeypot !== HONEYPOT_VALUE) {
        return { success: true, honeypot: true }
      }

      const errorField = (field) => `Erreur, merci de renseigner ${field}`

      if (this.type === this.TYPE_CLIENT && !checkInput(this.store, [INPUT_NOT_EMPTY]))
        return { success: false, message: errorField('votre établissement') }
      if (!checkInput(this.lastname, [INPUT_NOT_EMPTY]))
        return { success: false, message: errorField('votre nom') }
      if (!checkInput(this.firstname, [INPUT_NOT_EMPTY]))
        return { success: false, message: errorField('votre prénom') }
      if (!checkInput(this.email, [INPUT_NOT_EMPTY, INPUT_VALID_MAIL]))
        return { success: false, message: errorField('un email valide') }
      if (!checkInput(this.phone, [INPUT_NOT_EMPTY, INPUT_VALID_PHONE]))
        return {
          success: false,
          message: errorField('un numéro de téléphone valide'),
        }
      if (!checkInput(this.message, [INPUT_NOT_EMPTY]))
        return { success: false, message: 'Erreur, le message est vide' }
      if (!this.optin)
        return { success: false, message: "Erreur, merci de confirmer votre accord pour que vos données soient traitées." }

      return { success: true }
    },

    async handleSubmit(e) {
      if (this.status !== 'ready') return

      this.status = 'pending'

      const { success, message, honeypot } = this.validateForm()

      if (honeypot) {
        this.handleSuccess()
        return
      }

      if (!success) {
        this.handleMessage(message)
        return false
      }

      const formData = new FormData(e.target)
      const body = Object.fromEntries(formData.entries())

      // Ensure mybeersExtra is current by reading cookie again before each submission
      const currentMybeersCookie = Cookies.get('mybeers');
      if (currentMybeersCookie) {
        try {
          this.mybeersExtra = JSON.parse(currentMybeersCookie);
          this.log("Re-read mybeers cookie before submission:", this.mybeersExtra);
        } catch (e) {
          this.log("Error re-parsing mybeers cookie:", e);
        }
      }

      // Get the mybeers cookie contents
      if (this.mybeersExtra) {
        body.mybeersExtra = this.mybeersExtra;
      }

      let response
      try {
        if (process.env.NODE_ENV === 'production') {
          response = await sendMail(body);
        } else {
          this.log("Simulating email sending in non-production environment.");
          response = await fakeSendMail(body);
        }
      } catch (err) {
        this.handleMessage('Erreur interne, réessayer ultérieurement')
        return
      }

      this.status = 'ready'

      if (response.status !== 200) {
        this.handleMessage('Erreur serveur, réessayer ultérieurement')
        return
      }

      this.handleSuccess()
    },
  },
}

function fakeSendMail(data) {
  return new Promise((resolve) => {
    this.log("Simulated sendMail called with data:", data);
    setTimeout(() => {
      resolve({ status: 200, message: "Simulated success" });
    }, 1000); //
  });
}

